import React, { useContext, useState, useEffect } from "react";
import { items } from "../components/AllData.js";
import TrendingSlider from "../components/TrendingSlider.jsx";
import Newsletter from "../components/Newsletter.jsx";
import Footer from "../components/Footer.jsx";
import Navbar from "../components/Navbar.jsx";
import { useParams } from "react-router-dom";
import { CartContext } from "../App";
import "../pages/ProductPage.css";

// Function to render stars based on the rating
const renderStars = (rating) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<span key={i} className="star filled">★</span>); // Filled star
    } else {
      stars.push(<span key={i} className="star">☆</span>); // Empty star
    }
  }
  return stars;
};

function ProductPage() {
  const { id } = useParams();
  const item = items.find((item) => item.id === parseInt(id)); // Fetch item from AllData.js
  const { addToCart } = useContext(CartContext);

  const [quantity, setQuantity] = useState(1);
  const [image, setImage] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);
  const [selectedSize, setSelectedSize] = useState(item?.sizes ? item.sizes[0] : "");
  const [selectedColor, setSelectedColor] = useState(item?.colors?.[0]?.color || "default");

  const [notify, setNotify] = useState(false);

  useEffect(() => {
    if (item) {
      const defaultColor = item.colors && item.colors[0];
      setImage(defaultColor?.img || item.img);
      setAdditionalImages(defaultColor?.additionalImgs || item.otherImgs || []);
    }
  }, [item]);

  const handleColorChange = (color, img, additionalImgs) => {
    setSelectedColor(color);
    setImage(img);
    setAdditionalImages(additionalImgs || []);
  };

  const calcPrice = () => {
    const price = item?.prices ? item.prices[selectedSize] : item?.price;
    return quantity * price;
  };

  const handleAddToCart = () => {
    const price = item?.prices ? item.prices[selectedSize] : item?.price;
    addToCart({
      ...item,
      quantity,
      size: selectedSize,
      color: selectedColor,
      img: image,
      price,
    });
    setNotify(true);
    setTimeout(() => setNotify(false), 2000);
  };

  const changeImage = (e) => setImage(e.target.src);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  if (!item) return <p>Loading...</p>;

  return (
    <>
      <Navbar />
      <div onAnimationEnd={() => setNotify(false)} className={`notify ${notify ? "slide-in" : ""}`}>
        <p>Item has been added to the cart &nbsp; ✅</p>
      </div>

      <div className="product-page-div">
        <div className="container">
          <div className="product-div">
            <h3 className="product-big-name">{item.description}</h3>
            <div className="product-left">
              <div className="big-img">
                <img src={image} alt="product" />
              </div>
              <div className="small-imgs">
                <img
                  src={item.img}
                  alt="default"
                  onMouseOver={changeImage}
                  style={{ display: selectedColor === (item?.colors?.[0]?.color || "default") ? "block" : "none" }}
                />
                {additionalImages.map((imgSrc, index) => (
                  <img key={index} src={imgSrc} alt={`preview-${index}`} onMouseOver={changeImage} />
                ))}
              </div>
            </div>
            <div className="product-right">
              <p className="product-spec">{item.specs}</p>

              {/* Render the rating out of 5 stars */}
              <div className="product-rating">
                <p>{renderStars(item.ratings)} 0 Reviews</p>
              </div>

              {item?.sizes && (
                <div className="product-size">
                  <p>Select Size:</p>
                  <div className="size-buttons">
                    {item.sizes.map((size) => (
                      <button
                        key={size}
                        onClick={() => setSelectedSize(size)}
                        className={selectedSize === size ? 'selected' : ''}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {item?.colors && item.colors.length > 0 && (
                <div className="product-color">
                  <p>Select Color:</p>
                  <div className="color-buttons">
                    {item.colors.map(({ color, img, additionalImgs }) => (
                      <button
                        key={color}
                        onClick={() => handleColorChange(color, img, additionalImgs)}
                        className={selectedColor === color ? 'selected' : ''}
                      >
                        {color}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              <div className="product-quant">
                <p>Quantity</p>
                <div className="product-btns">
                  <button onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</button>
                  <p className="quantity">{quantity}</p>
                  <button onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
                <p className="product-price">${calcPrice().toFixed(2)}</p>
              </div>
              <div className="atc-buy">
                <button onClick={handleAddToCart} className="atc-btn">
                  add to cart
                </button>
              </div>
            </div>
          </div>

          <div className="specifications">
            <div className="spec">
              <p className="spec-title">Texture:</p>
              <p className="title-desc">{item.texture}</p>
            </div>
            <div className="spec">
              <p className="spec-title">Reviews:</p>
              <div className="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="66b68ad91fa29c02898a37f3" data-style-height="52px" data-style-width="100%">
                <a href="https://www.trustpilot.com/review/kulit.us" target="_blank" rel="noopener">Trustpilot</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TrendingSlider />
      <Newsletter />
      <Footer />
    </>
  );
}

export default ProductPage;
